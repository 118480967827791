import React from 'react';
import {Box, Typography, Chip, CircularProgress} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useSnackBarContext} from "../../context/SnackBarContext";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import axiosHttp from "../../utils/axios";
import {storage} from "../../utils/storage";

import moment from 'moment';
import 'moment-timezone';
import {config} from "../../config/config";

export function formatInfoData(data: any, isFindHelpEnable: boolean, setPatientInfo: any) {
    var now = moment(new Date()).tz("America/New_York");
    var dob = moment(data?.dob).utc();
    let divisionId = undefined;
    let iClinicVisitInfo = undefined;
    if(config.rendr.ids.includes(parseInt(data?.clinicInfos[0]?.ClinicID))){
        divisionId = data?.divisionId;
    }
    // permission check for iClinicVisitInfo
    if(data?.permissions.some((item: any) => item?.PermissionName === 'rpa_demographic')){
        iClinicVisitInfo = data?.iClinicVisitInfo;
    }
    let temp = {
        name: data?.lastName + ' ' + data?.firstName,
        dob: (data?.dob ? moment(data?.dob).utc().format('MM/DD/YYYY') : 'Unknown'),
        age: now.diff(dob, 'year') + 'y',
        gender: data?.gender || 'Unknown',
        pid: data?.clinicInfos[0]?.PatientID,
        clinicId: data?.clinicInfos[0]?.ClinicID,
        divisionId: divisionId,
        planTypeName: data?.planTypeName || 'Unknown',
        isFindHelpEnable: isFindHelpEnable || false,
        iClinicVisitInfo: iClinicVisitInfo
    }
    if (temp.name === ' ') {
        temp.name = 'Unknown'
    }
    if (temp.dob === 'Unknown') {
        temp.age = 'Unknown'
    }
    setPatientInfo(temp)
    return (temp)
}

interface PatientInfoDetailViewContentProps{
    data: any,
    infoString: string,
    handleClick: ()=>void

}

export const PatientInfoDetailViewContent = (props: PatientInfoDetailViewContentProps)=>{
    const {data, infoString, handleClick} = props;
    return(<Box sx={{padding: '24px 16px 16px 16px'}} position={'relative'}>
        <Box>
            <Typography variant={'subtitle2'} position={'absolute'} right={16} bottom={1}>Clinic ID: {data?.clinicId} {data?.divisionId?`(${data?.divisionId})`: ""}</Typography>
            <Typography variant={'h2'}>{data?.name ?? 'N/A'}</Typography>
            <Typography variant={'subtitle1'}>{infoString.split('|')[0]??''}</Typography>
            <Typography variant={'subtitle1'} mb={'4px'}>{infoString.split('|')[1]??''}</Typography>
            <Chip
                sx={{background: '#EDF6FF', color: '#26ACE2',height: '24px', borderRadius: '8px'}}
                label={<div style={{fontWeight: 700, fontSize: 12, lineHeight: '16px'}}>Patient
                    ID: {data?.pid}</div>}
                onClick={() => handleClick()}
                onDelete={() => handleClick()}
                deleteIcon={<ContentCopyIcon sx={{"&&": {color: "#26ACE2", fontSize: 16}}}/>}
            />
        </Box>
    </Box>)
}

export const PatientInfoDetailView = () => {
    const {setOpen, setMessage} = useSnackBarContext();
    const [data, setData] = React.useState({} as any);
    const [loading, setLoading] = React.useState(true)
    const {mpid, setPatientInfo, setMpid, setLanguages, setLanguageId, setIClinicDictionary} = React.useContext(QuestionaryContext);

    function formatInfo() {
        return `${data?.dob} ${data?.age} ${data?.gender} | Plan Type: ${data?.planTypeName}`
    }

    React.useEffect(() => {
        const accessToken = storage.getItem("AccessToken", undefined);
        if (!accessToken) {
            setMpid("");
            return;
        }
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant', {
                    "mpid": mpid,
                    "action": "BasicInfo"
                })
                const isEnable = await axiosHttp.post('/CommunityResource/Findhelp', {
                    "mpid": mpid,
                    "action": "IsFindHelpEnabled"
                });
                setData(formatInfoData(response?.data?.data, isEnable.data.data, setPatientInfo));
                setLoading(false);
                try{
                    const response = await axiosHttp.post('/RPA', {
                        action: "getRpaIClinicDictionary",
                        mpid: mpid,
                    });
                    let dictionary = response.data.data;
                    if(dictionary){
                        setIClinicDictionary(dictionary);
                    }
                }
                catch(e){
                    console.log(e)
                }
                try {
                    const response = await axiosHttp.post('/Questionary/Participant', {
                        "mpid": mpid,
                        "action": "AllLanguages"
                    })
                    let temp = response.data.data;
                    setLanguages(temp);
                    setLanguageId(temp[0].LanguageId)
                } catch (e) {
                    console.log(e)
                }
            } catch (e) {
                console.log(e)
            }
        }
        if (mpid) {
            getData();
        }
    }, [mpid])

    function handleClick() {
        setMessage('PatientId copied successfully')
        setOpen(true);
        navigator.clipboard.writeText(data?.pid)
    }

    return (
        loading ?
            <Box padding={'16px 16px 24px 16px'} display={'flex'} justifyContent={'center'}><CircularProgress/></Box> :
            <PatientInfoDetailViewContent
                data={data}
                infoString={formatInfo()}
                handleClick={handleClick}
            />)
}