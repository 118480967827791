import * as React from 'react';
import {FormControl, LinearProgress, MenuItem, OutlinedInput, Select, Typography,} from "@mui/material";
import Box from "@mui/material/Box";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import axiosHttp from "../../utils/axios";
import {useNavigate, useParams} from "react-router-dom";
import {HistoryQuestionHeader} from "./components/HistoryQuestionHeader";
import {BlackDisabledTextField} from "../../styledComponents/BlackDisabledTextField";
import {BlackDisabledFormControlLabel} from "../../styledComponents/BlackDisabledFormControlLabel";
import {BlackDisabledCheckBox} from "../../styledComponents/BlackDisabledCheckBox";

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';

export const getListOfAnswersText = (answers: any[], data: any) => {
    let text = ''
    text = answers.filter((answer: any) => data.answers.find((a: any) => a.AnswerCode === answer.AnswerCode)).map((answer: any) => {
        let original = data.answers.find((a: any) => a.AnswerCode === answer.AnswerCode);
        return answer.AnswerCode + (original?.FreeFormContent ? (':' + original.FreeFormContent) : '');
    }).join(', ').replace('::', ':');
    return text;
}

export const ViewFinish = () => {
    const {mpid, languages} = React.useContext(QuestionaryContext);
    const [data, setData] = React.useState({} as any)
    const [loading, setLoading] = React.useState(true)
    const [languageId, setLanguageId] = React.useState(languages?languages[0]?.LanguageId:1);

    let {responseSessionId} = useParams();
    const navigate = useNavigate();
    React.useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant', {
                    "mpid": mpid,
                    "responseSessionId": responseSessionId,
                    "action": "SessionAnswers",
                })
                setData(response?.data?.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        };
        getData();
    }, [responseSessionId])

    const getColor = (sectionName: string) => {
        // if (data.hrsnSections?.find((hrsn: any) => hrsn.sectionName === sectionName)) {
        //     return 'error.main'
        // } else {
        return 'text.primary'
        // }
    }

    function getSectionTranslation(section: any, attribute: string, defaultValue?: string){
        try{
            let result = section?.SectionTranslation?.find((tran: any)=>tran.LanguageId===languageId)?.[`${attribute}`]
            return result || defaultValue
        }
        catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    function getQuestionTranslation(question: any, attribute: string, defaultValue?: string){
        try{
            let result = question?.QuestionTranslation?.find((tran: any)=>tran.LanguageId===languageId)?.[`${attribute}`]
            return result || defaultValue
        }
        catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    function getAnswerTranslation(answer: any, attribute: string, defaultValue?: string){
        try{
            let result = answer?.AnswerTranslation?.find((tran: any)=>tran.LanguageId===languageId)?.[`${attribute}`]
            return result || defaultValue
        }
        catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    return (
        <Box
            sx={{borderRadius: '8px', maxHeight: '100%'}}
            position={'relative'}
            display="flex"
            bgcolor={'#FFFFFF'}
            flexDirection={'column'}
            justifyContent={'center'}>
            {loading ? null : <CloseIcon className='closeButton no-print' onClick={() => navigate('/menu')}/>}
            {loading ? null : (
                <HistoryQuestionHeader data={data} responseSessionId={responseSessionId??''}/>
            )}
            {loading ? null :
                <Box display={'flex'} padding='8px 24px 8px 24px' className={'no-print'}>
                    <FormControl fullWidth size={'small'}>
                        <Typography variant='h5'>Language</Typography>
                        <Select
                            id="demo-simple-select"
                            value={languageId}
                            input={<OutlinedInput sx={{fontSize: '14px', fontWeight: 400}} />}
                        >
                            {languages.filter((l: any) => data?.questionaryDeepNested?.QuestionaryTranslation.some((d: any) => d.LanguageId === l.LanguageId)).map((language: any) => {
                                return <MenuItem
                                    key={language.LanguageId}
                                    value={language.LanguageId}
                                    onClick={() => {
                                        setLanguageId(language.LanguageId)
                                    }}
                                >{language.DisplayText}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
            }
            <Box sx={{flex: '1 1 auto', overflowY: 'auto'}} padding='0 24px 0 24px' className='no-scroll-print'>
                {loading ? <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>
                    : data?.questionaryDeepNested?.Section.map((section: any, sectionIndex: number) => {
                        return (
                            <div key={section.SectionName + '_' + sectionIndex}>
                                <Typography mt={2} variant="h3">{getSectionTranslation(section, 'SectionContent', section.SectionName)}</Typography>
                                {section.SectionDescription?<Typography mt={2} variant="body2">
                                    {getSectionTranslation(section, 'SectionDescription', section.SectionDescription)}
                                </Typography>:null}
                                {section.Question.map((question: any, questionIndex: number) => {
                                    let text = getListOfAnswersText(question.Answer, data);
                                    let textArray = text.split(',');
                                    return (
                                        <Box key={question.QuestionCode + section.SectionName + questionIndex}
                                             display="flex"
                                             alignItems="self-start"
                                             flexDirection={'column'}
                                             justifyContent={'space-between'}
                                             sx={{color: getColor(section.SectionName)}}
                                        >
                                            <Typography variant='h5'  mt={1.5} mb={1}>{getQuestionTranslation(question, 'QuestionContent', question.QuestionName)}</Typography>
                                            <Box display="flex"
                                                 alignItems="self-start"
                                                 flexDirection={'column'}
                                                 justifyContent={'space-between'}>
                                                {question.Answer.map((answer: any, index: number) => {
                                                    return (
                                                        <div key={answer.AnswerCode}  style={{minHeight: '24px',paddingBottom: index!==question.Answer.length-1?'8px':0}}>
                                                            <BlackDisabledFormControlLabel
                                                                color={'primary'}
                                                                disabled={true}
                                                                control={<BlackDisabledCheckBox
                                                                    size={'small'}
                                                                    style={{
                                                                        transform: "scale(0.87)",
                                                                    }}
                                                                    sx={{padding: '0px 9px 0px 9px'}}
                                                                    icon={<RadioButtonUncheckedIcon/>}
                                                                    checkedIcon={<RadioButtonCheckedIcon/>}
                                                                    checked={text.includes(answer.AnswerCode)}/>}
                                                                label={<div className={'answerText'}>{getAnswerTranslation(answer, 'AnswerTranslationContent',answer?.AnswerName)}</div>}/>
                                                            {(answer.IsFreeForm && text.includes(answer.AnswerCode)) ?
                                                                <BlackDisabledTextField
                                                                    InputProps={{
                                                                        style: {
                                                                            borderRadius: "8px",
                                                                        }
                                                                    }}
                                                                    disabled={true}
                                                                    value={textArray?.find((answerText: string) => answerText.includes(answer.AnswerCode))?.split(':')[1] || ''}
                                                                    margin={'dense'}
                                                                    size={'small'}/> :
                                                                null}
                                                        </div>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </Box>
        </Box>
    )
}